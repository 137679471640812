import React, { useEffect } from 'react'
import Layout from "../../share/layout";
import '../../../styles/font-awesome-4.7.0/css/font-awesome.css'
import Image0 from '../../../images/sonidosaudiovisuales/face.png'
import Image1 from '../../../images/sonidosaudiovisuales/artista.png'
import Image2 from '../../../images/sonidosaudiovisuales/back.png'
import styles from '.././global.css'
import '.././movil.css'

const Emilio = () => {
    useEffect(() => {
        document.body.classList.add('body-sonidos-visuales');
        return function cleanup() {
            document.body.classList.remove('body-sonidos-visuales');
        };
    }, [])
    return (
        <Layout
            title="Geografías | UPC Cultural"
            metaTitle="Geografías | UPC Cultural"
            description="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos. "
            subtitile="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            metaDescripcin="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            imgurl="//images.ctfassets.net/bv5rnjawitjg/E9aFHf11TxaEIX6JuU3vL/550f80daa53881d13f10dc95466806e2/800_x_800-1.png?h=300"
            keywords="Multimedia, Audiovisual, Luis Alvarado, Emilio Le Roux, Vered Engelhard, Luis Sanz, Paola Torres Núñez del Prado, Jonathan Castro Alejos, Lucía Beaumont."
        >
            <section id="section-content" class="section-content">
                <div class="region-inner region-content-inner">
                    <div class="face"><a href="https://www.facebook.com/upccultural1/" target="_blank"><img src={Image0} /></a></div>
                    <div id="page1" className="page page1 pdetalles ">
                        <div className="inner">
                            <span className="bg1"></span>
                            <span className="bg2"></span>
                            <span className="bglogo2"><span className="logoimg"></span></span>
                            <div className="row">
                                <div className="col cleft">
                                    <div className="video">
                                        <iframe id="video1" src="https://player.vimeo.com/video/726078159?h=6f4ce6d1cc&color=606060&title=0&byline=0&portrait=0" width="640" height="360" frameBorder="0" allow="picture-in-picture" allowfullscreen></iframe>

                                    </div>
                                    <div className="video">
                                        <iframe id="video2" src="https://player.vimeo.com/video/726079392?h=a360942697&color=606060&title=0&byline=0&portrait=0" width="640" height="640" frameBorder="0" allow="picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    <div className="video">
                                        <iframe id="video3" src="https://player.vimeo.com/video/726082540?h=61cef55215&color=606060&title=0&byline=0&portrait=0" width="640" height="640" frameBorder="0" allow="picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    <div className="video">

                                        <iframe id="video4" src="https://player.vimeo.com/video/726083897?h=9a34d62e29&color=606060&title=0&byline=0&portrait=0" width="640" height="640" frameBorder="0" allow="picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    <div className="video">

                                        <iframe id="video5" src="https://player.vimeo.com/video/726085663?h=dac6fdf8a1&color=606060&title=0&byline=0&portrait=0" width="640" height="640" frameBorder="0" allow="picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    <div className="video">

                                        <iframe id="video6" src="https://player.vimeo.com/video/726087075?h=a03c2f6e7f&color=606060&title=0&byline=0&portrait=0" width="640" height="640" frameBorder="0" allow="picture-in-picture" allowfullscreen></iframe>

                                    </div>

                                </div>
                                <div className="col cright">
                                    <div className="artista"><img src={Image1} /></div>
                                    <div className="titulo">
                                        <div className="nombre"><b>Emilio Le Roux</b></div>
                                        <div className="obra"><b>Obra:</b> Música Imaginaria</div>
                                    </div>
                                    <div className="detalle">
                                        <p>
                                            Las ejecuciones fueron lecturas/interpretaciones basadas en improvisación espontánea, que tenian como objetivo la práctica creativa en el momento presente y no una preparación de repertorio para presentación futura, que es uno de los fundamentos de mi trabajo en estas partituras gráficas.<br />
                                            1.-Trílcide (2'15'')<br />
                                            2.-Inconstrução (1'19'')<br />
                                            3.-Diálogos (2'09")<br />
                                            4.-Desamor (4'28'')<br />
                                            5.-Contraponto (4'10'')<br />
                                            6.-Círculos (4'37'')
                                        </p>
                                        <p>Sobre los ejecutantes:</p>
                                        <p>En la pieza vocal, Inconstrução, participaron los cantantes del Coral Ecuménico:</p>
                                        <p>Bob Whisley<br />
                                            Van Costa<br />
                                            Wesley Lima<br />
                                            Jean Prado<br />
                                            Filipe Miranda<br />
                                            Alba Valéria</p>
                                        <p>Las otras piezas fueron interpretadas por:</p>
                                        <p>Flávio Hamaoka (flauta)<br />
                                            Ícaro Aranguez Sleifer (guitarra eléctrica)<br />
                                            Emilio Le Roux (clarinete)</p>
                                        <p>Sobre el proyecto:<br />
                                            El Libro Música Imaginaria, del cual presento algunas páginas y sus interpretaciones libres, es una colección de piezas gráficas introductorias a la improvisación para músicos profesionales y aficionados de todos los niveles.<br />
                                            Basado en conceptos de obra abierta, improvisación libre y multidisciplinariedad, el libro presenta ideas musicales de manera visual. Así, provoca diálogos, formas, armonías espontáneas, interacciones inesperadas y otros fenómenos donde lo más importante es el presente, lo que acontece en el momento, como una alternativa didáctica y creativa al aprendizaje mediante la reproducción de repertorio.</p>
                                        <p>Biografía<br />
                                            Emilio Le Roux (Lima, 1970) es diseñador gráfico, director audiovisual, compositor y multinstrumentista. Su interés por la música comienza en el Perú, en contacto con instrumentos andinos y afroandinos y, posteriormente, con el ambiente de la música erudita y contemporánea. Es graduado en Composición en la Universidad Federal de Bahía, Brasil, país donde reside desde 1998. Actualmente desarrolla un trabajo que transita entre las artes visuales, la improvisación y el indeterminismo en la música.</p>
                                    </div>
                                    <a className="btnback" href='/galeria/geografias'>
                                        <a data-page="p0"><img src={Image2} /></a>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </Layout>
    )
}

export default Emilio